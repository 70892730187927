import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import Logo from "../public/transparent-logo.png";
import Logo from "../public/TransparentLogo.png";

import MobileLogo from "../public/MobileLogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NewNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "Services",
      to: "/services",
    },
    {
      name: "Contact",
      to: "/contact",
    },
  ];

  return (
    <Disclosure as="nav" className="max-w-full sticky top-0  z-[2] bg-white">
      {({ open }) => (
        <div className="bg-[#111] py-8">
          <div className="max-w-7xl mx-auto">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => navigate("/")}
                className="flex flex-shrink-0 items-center cursor-pointer"
              >
                <img
                  className="block w-13 h-12 lg:hidden"
                  src={MobileLogo}
                  alt="MimTaxi"
                />
                <img
                  className="hidden w-10 h-10 lg:block "
                  src={Logo}
                  alt="MimTaxi"
                />
              </div>
              <div className="inline-grid text-white hover:text-amber-500 text-2xl cursor-default">
                <a
                  className="text-center cursor-pointer"
                  href={`tel:01252311111`}
                >
                  01252 311 111
                </a>
                <span className="text-center">MIM Taxis</span>
              </div>
              <div className="hidden md:ml-6 md:flex md:space-x-8">
                {navigation.map((item, index) => (
                  <Link
                    key={index}
                    to={item.to}
                    className={`${
                      location.pathname === item.to
                        ? " border-amber-500   text-amber-500"
                        : "border-transparent  text-white hover:border-amber-500 hover:text-amber-500"
                    } inline-flex items-center border-b-2 px-1 text-md font-medium`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-amber-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item, index) => (
                <Disclosure.Button
                  key={index}
                  onClick={() => navigate(item.to)}
                  className={`${
                    location.pathname === item.to
                      ? " border-amber-500 bg-amber-100 text-amber-700 "
                      : " border-transparent text-gray-500 hover:border-amber-300 hover:bg-amber-50 hover:text-amber-700 "
                  } text-start w-full block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6`}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

              {/* <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
              >
                Team
              </Disclosure.Button> */}

              {/* <Disclosure.Button
                onClick={() => navigate("/contact")}
                className="text-start w-full block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 border-transparent text-gray-500 hover:border-amber-300 hover:bg-amber-50 hover:text-amber-700 "
              >
                Contact
              </Disclosure.Button> */}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
export default NewNavBar;
