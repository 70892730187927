import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Img01 from "../public/images/image4.jpg";
import Img02 from "../public/images/image2.jpg";
import Img03 from "../public/images/image5.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper";
import { useNavigate } from "react-router";

const header = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-full">
      <div className="h-screen w-full">
        <Swiper
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
        >
          <SwiperSlide>
            <div
              className="h-screen bg-no-repeat bg-cover bg-center"
              style={{
                backgroundImage: `url(${Img01})`,
              }}
            >
              <div className="h-screen bg-black bg-opacity-10 ">
                <div className="h-screen flex flex-col justify-center items-center space-y-6 ">
                  <p className="">
                    {/* <span className="text-center font-bold">01252 311 111</span> */}
                    <a
                      className="text-7xl text-white md:mt-14 lg:mt-14 mt-0 text-center font-bold cursor-pointer"
                      href={`tel:01252 311 111`}
                    >
                      01252 311 111
                    </a>
                  </p>
                  <p className="text-7xl text-white md:mt-14 lg:mt-14 mt-0">
                    MIM Taxis
                  </p>
                  {/* <p className="text-2xl font-bold text-white ">
                    24 Hour Service
                  </p> */}
                  <p className="text-2xl font-bold text-white ">
                    Moving you around safely
                  </p>
                  <button
                    onClick={() => navigate("/contact")}
                    type="button"
                    className="text-white  bg-amber-600 hover:bg-amber-800   rounded-3xl  px-8 py-3 text-center mr-2 mb-2 "
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="h-screen bg-no-repeat bg-cover bg-center"
              style={{
                backgroundImage: `url(${Img02})`,
              }}
            >
              <div className="h-screen bg-black bg-opacity-10 ">
                <div className="h-screen flex flex-col justify-center items-center space-y-6">
                  <p className="text-7xl text-white ">Fast and Reliable</p>
                  <p className="text-2xl font-bold text-white ">
                    Taking to your destination safely
                  </p>
                  <button
                    onClick={() => navigate("/contact")}
                    type="button"
                    className="text-white  bg-amber-600 hover:bg-amber-800   rounded-3xl  px-8 py-3 text-center mr-2 mb-2 "
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="h-screen bg-no-repeat bg-cover bg-center"
              style={{
                backgroundImage: `url(${Img03})`,
              }}
            >
              <div className="h-screen bg-black bg-opacity-10 ">
                <div className="h-screen flex flex-col justify-center items-center space-y-6">
                  <p className="text-7xl text-white ">Airport Transfer</p>
                  <p className="text-2xl font-bold text-white ">
                    Travel to Heathrow or Gatwick
                  </p>
                  <button
                    onClick={() => navigate("/contact")}
                    type="button"
                    className="text-white  bg-amber-600 hover:bg-amber-800   rounded-3xl  px-8 py-3 text-center mr-2 mb-2 "
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
export default header;
