import {
  FaEnvira,
  FaCodepen,
  FaBusAlt,
  FaDrawPolygon,
  FaWheelchair,
  FaPoundSign,
} from "react-icons/fa";
const Service = () => {
  return (
    <div className="mx-auto max-w-7xl px-4">
      <div className="py-24 flex flex-wrap items-stretch justify-between md:flex-nowrap flex-wrap gap-4">
        <div className="w-full services-section-mx md:w-3/12  py-8 group hover:shadow-lg shadow-md rounded-md hover:bg-amber-500">
          <div className="flex flex-col space-y-4 items-center justify-center text-center">
            <FaEnvira className="w-16 h-16 text-amber-600 bg-gray-100 rounded-full p-1" />
            <p className="text-2xl font-medium text-gray-600 group-hover:text-white">
              Safety First
            </p>
            <p className="text-gray-600  w-9/12 group-hover:text-white">
              Experienced and professionally trained staff
            </p>
          </div>
        </div>
        <div className="w-full services-section-mx md:w-3/12  py-8 group hover:shadow-lg shadow-md rounded-md hover:bg-amber-500">
          <div className="flex flex-col space-y-4 items-center justify-center text-center">
            <FaPoundSign className="w-16 h-16 text-amber-600 bg-gray-100 rounded-full p-1" />
            <p className="text-2xl font-medium text-gray-600 group-hover:text-white">
              Reasonable Rates
            </p>
            <p className="text-gray-600  w-9/12 group-hover:text-white">
              We can offer you the right vehicle at the right price to fit your
              budget
            </p>
          </div>
        </div>

        <div className="w-full services-section-mx md:w-3/12  py-8 group hover:shadow-lg shadow-md rounded-md hover:bg-amber-500">
          <div className="flex flex-col space-y-4 items-center justify-center text-center">
            <FaWheelchair className="w-16 h-16 text-amber-600 bg-gray-100 rounded-full p-1" />
            <p className="text-2xl font-medium text-gray-600 group-hover:text-white">
              Accessiblity
            </p>
            <p className="text-gray-600 w-9/12 group-hover:text-white">
              Wheelchair-accessible vehicles available
            </p>
          </div>
        </div>

        <div className="w-full services-section-mx md:w-3/12  py-8 group hover:shadow-lg shadow-md rounded-md hover:bg-amber-500">
          <div className="flex flex-col space-y-4 items-center justify-center text-center">
            <FaDrawPolygon className="w-16 h-16 text-amber-600 bg-gray-100 rounded-full p-1" />
            <p className="text-2xl font-medium text-gray-600 group-hover:text-white">
              Coverage
            </p>
            <p className="text-gray-600 w-9/12 group-hover:text-white">
              Aldershot, Farnborough and surrounding areas
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service;
