import {
  FaPhoneAlt,
  FaMapMarkedAlt,
  FaRegClock,
  FaAngleRight,
  FaMapMarkerAlt,
  FaPhone,
  FaCalendarWeek,
  FaInbox,
} from "react-icons/fa";
// import Logo from "../public/transparent-logo.png";
import Logo from "../public/TransparentLogo.png";
import MasterCard from "../public/payment/mastercard-26149.png";
import VisaCard from "../public/payment/visa-logo-png-2020.png";
import Contactless from "../public/payment/26336453.jpg";
import applePay from "../public/payment/apple-pay (1).png";
import americanExpress from "../public/payment/epress.png";
import maestroCard from "../public/payment/maestro.png";

const footer = () => {
  return (
    <div className="">
      <div className="bg-amber-500 py-6 px-4">
        <div className="grid lg:grid-cols-3 gap-20 max-w-7xl mx-auto">
          <div className="col-span-1 flex flex-row space-x-3">
            <FaMapMarkedAlt className="w-12 h-12 text-white p-1" />
            <div className="space-y-2">
              <p className="text-white font-bold">ADDRESS</p>
              <p className="text-sm text-white font-medium capitalize">
                28 printing house court, Sebastopol road, Aldershot, GU11 1DH
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-row space-x-3">
            <FaPhoneAlt className="w-12 h-12 text-white  p-1" />
            <div className="space-y-2">
              <p className="text-white font-bold">BOOK A RIDE</p>
              <p className="text-sm text-white font-medium">
                <a className="cursor-pointer" href={`tel:01252311111`}>
                  01252 311 111
                </a>
                {/* BOOK A RIDE: (0481) 123 987 2411  */}
                {/* OFFICE: (0481) 123 987 2412 */}
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-row space-x-3">
            <FaRegClock className="w-12 h-12 text-white  p-1" />
            <div className="space-y-2">
              <p className="text-white font-bold">Working Hours</p>
              <div className="text-sm text-white font-medium">
                <p className="col-span-1 row-span-2">
                  {/* Mon-Sat 08:00am - 02:00am */}
                  Mon-Sun: 24 hours service
                </p>

                <p className=" col-span-1">
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  {/* {`Sunday`}&nbsp;&nbsp;&nbsp;{`08:00am - 00:00am`} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f3f3f3] py-8 px-4">
        <div className="mx-auto max-w-7xl">
          <div className=" flex flex-col space-y-24 ">
            <div className="grid lg:grid-cols-4 gap-8">
              <div className="block lg:hidden col-span-4 space-y-8">
                <div className="grid grid-cols-3">
                  <div className="col-span-1">
                    <img className="p-4" src={Logo} alt="Logo" />
                  </div>
                  <div className="col-span-2" style={{ marginTop: "12px" }}>
                    <p className="text-gray-500">
                      Hire airport run taxis for Farnborough, Aldershot and
                      surrounding areas. <br />
                      <b>Advance Booking Only!</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden lg:block md:block col-span-1 space-y-8">
                <img
                  style={{
                    width: "25%",
                    height: "auto",
                  }}
                  src={Logo}
                  alt="Logo"
                />

                <p className="text-gray-500">
                  Hire airport run taxis for Farnborough, Aldershot and
                  surrounding areas. <br />
                  <b>Advance Booking Only!</b>
                </p>
              </div>
              <div className=" md:col-span-1 lg:col-span-1 col-span-4  space-y-8">
                <div className="flex flex-col space-y-2">
                  <p className="text-gray-600 font-medium">OUR SERVICES</p>
                  <p className="border-solid border border-amber-500  w-1/4 "></p>
                </div>
                <div className="flex flex-col space-y-1">
                  {/* <div className="flex flex-row items-center space-x-2">
                    <FaAngleRight className="w-8 h-8 text-gray-500  p-1" />
                    <span className="text-gray-500">
                      <p className="text-gray-500 col-span-1 row-span-2">
                        Mon-Sat: 08:00am - 02:00am
                      </p>

                      <p className="text-gray-500 col-span-1">
                        
                        &nbsp;
                        {` Sunday: 08:00am - 00:00am`}
                      </p>
                    </span>
                  </div> */}
                  <div className="flex flex-row items-center space-x-2">
                    <FaAngleRight className="w-8 h-8 text-gray-500  p-1" />
                    <p className="text-gray-500">Airport Transfers</p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <FaAngleRight className="w-8 h-8 text-gray-500  p-1" />
                    <p className="text-gray-500">Group Bookings</p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <FaAngleRight className="w-8 h-8 text-gray-500  p-1" />
                    <p className="text-gray-500">7 Seaters Available</p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <FaAngleRight className="w-8 h-8 text-gray-500  p-1" />
                    <p className="text-gray-500">
                      Wheelchair Accessible Vehicles
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:col-span-1 lg:col-span-1 col-span-4 space-y-8">
                <div className="flex flex-col space-y-2">
                  <p className="text-gray-600 font-medium">MimTaxis</p>
                  <p className="border-solid border border-amber-500  w-1/4 "></p>
                </div>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-row items-center space-x-4">
                    <FaMapMarkerAlt className="w-6 h-6 text-gray-500  font-light" />
                    <p className="text-gray-500 capitlize">
                      28 Printing House Court, Sebastopol Road, Aldershot, GU11
                      1DH
                    </p>
                  </div>
                  <div className="flex flex-row items-center space-x-4">
                    <FaPhone className="w-6 h-6 text-gray-500" />
                    <a
                      className="text-gray-500 cursor-pointer"
                      href={`tel:01252311111`}
                    >
                      01252 311 111
                    </a>
                  </div>
                  {/* <div className="flex flex-row items-center space-x-4">
                  <FaPhoneSquareAlt className="w-6 h-6 text-gray-500 " />
                  <p className="text-gray-500">Dummy Number Here</p>
                </div> */}
                  <div className="flex flex-row items-center space-x-4">
                    <FaInbox className="w-6 h-6 text-gray-500 " />
                    <p className="text-gray-500">booking@mimtaxis.co.uk</p>
                  </div>
                  <div className="flex flex-row items-center space-x-4">
                    <FaCalendarWeek className="w-6 h-6 text-gray-500 " />
                    <span className="text-gray-500">
                      <span className="text-gray-500">
                        <p className="text-gray-500 col-span-1 row-span-2">
                          Mon-Sun: 24 hours service
                        </p>
                        {/* <p className="text-gray-500 col-span-1">
                          {`Sunday`}&nbsp;&nbsp;&nbsp;{`08:00am - 00:00am`}
                        </p> */}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-span-1 space-y-8">
              <div className="flex flex-col space-y-2">
                <p className="text-gray-600 font-medium">SUBSCRIBE</p>
                <p className="border-solid border border-amber-500  w-1/4 "></p>
              </div>
              <div className="flex flex-col space-y-4">
                <CustomInput
                  name="email"
                  type="email"
                  placeholder="Email Address..."
                />
                <button
                  type="submit"
                  className="text-white bg-amber-600 rounded hover:bg-amber-800 hover:border-black focus:ring-4 focus:ring-black-300 font-medium   w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Submit
                </button>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <div className="pt-10 mx-auto max-w-7xl flex flex-row justify-between">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">
              All cards accepted via in-vehicle chip & pin machines.
            </p>
            <div className="flex flex-row space-x-3">
              <img src={MasterCard} alt="master card" className="w-15 h-7" />
              <img src={VisaCard} alt="visa card" className="w-15 h-7" />
              <span style={{ marginTop: "-4px", height: "auto" }}>
                <img
                  src={americanExpress}
                  alt="american express"
                  className="w-15 h-auto"
                />
              </span>
              <img src={maestroCard} alt="maestro Card" className="w-15 h-7" />

              <img
                src={Contactless}
                alt="Contactless Card"
                className="w-15 h-7"
              />
              <img src={applePay} alt="Apple pay card" className="w-15 h-7" />
            </div>
          </div>

          {/* <p className="text-gray-600 font-medium">Copyright 2023 MimTaxis</p> */}
        </div>
      </div>
    </div>
  );
};
export default footer;
