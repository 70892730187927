import React, { useEffect, useState } from "react";
import _ from "lodash";
// import { useNavigate } from "react-router";
import CustomInput from "../components/Input/CustomInput";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/loader/Loader";

const Contact = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  // const BASE_URL = "http://localhost:5000/send-enquiry";
  const BASE_URL = "https://mimtaxi-hpiq.onrender.com/send-enquiry";

  const [enquiry, setEnquiry] = useState({
    name: null,
    email: null,
    phone: null,
    message: null,
  });

  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const checkEmail = (value) => {
    if (value)
      return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "Invalid email address"
        : "";
    return "";
  };

  const hasBlankSpaces = (str) => {
    if (!str) return false;
    return str.match(/^\s+$/) !== null;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string || string === undefined) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleValidate = () => {
    // console.log("errors", errors, enquiry);
    const fnErrors = _.cloneDeep(errors);
    if (!enquiry.name || enquiry.name.length === 0) {
      Object.assign(fnErrors, { name: "Name is required" });
    } else {
      Object.assign(fnErrors, { name: null });
    }

    if (
      !enquiry.email ||
      enquiry.email.length <= 0 ||
      checkEmail(enquiry.email)
    ) {
      const inValid = checkEmail(enquiry.email);
      if (inValid) {
        Object.assign(fnErrors, { email: "Invalid email address" });
      } else {
        Object.assign(fnErrors, { email: "Email is required" });
      }
    } else {
      Object.assign(fnErrors, { email: null });
    }

    if (!enquiry.phone || enquiry.phone === 0) {
      Object.assign(fnErrors, { phone: "Phone is required" });
    } else {
      Object.assign(fnErrors, { phone: null });
    }

    if (!enquiry.message || enquiry.message === 0) {
      Object.assign(fnErrors, { message: "Message is required" });
    } else {
      Object.assign(fnErrors, { message: null });
    }

    if (
      Object.values(fnErrors).filter((x) => x !== null).length > 0 ||
      checkEmail(enquiry.email)
    ) {
      setErrors({ ...fnErrors });
      return true;
    }
    return false;
  };

  const sendEnquiry = async () => {
    if (handleValidate()) {
      return;
    }

    try {
      const params = {
        method: "POST",
        baseURL: BASE_URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      Object.assign(params, {
        data: { ...enquiry },
      });
      setShowLoading(true);
      const response = await axios(params);
      if (response.data) {
        setShowLoading(false);
        const dummy = {
          name: null,
          email: null,
          phone: null,
          message: null,
        };
        setEnquiry({ ...dummy });
        setErrors({});
        toast.success("Enquiry successfully sended!");
      }
    } catch (e) {
      setShowLoading(false);
      console.log("e", e.message);
      toast.error("Something went wrong!");
    }
  };

  const getError = (value) => {
    if (!value) return "";
    return errors && errors[value] && errors[value].length > 0
      ? errors[value]
      : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({
        ...errors,
        [name]: `${capitalizeFirstLetter(name)} is required`,
      });
    } else if (name === "email" && value && value.length > 0) {
      const inValid = checkEmail(value);
      if (inValid) {
        setErrors({ ...errors, email: "Invalid email address" });
      } else {
        setErrors({ ...errors, email: null });
      }
      return;
    } else if (errors[name] && !hasBlankSpaces(value) && value.length > 0) {
      setErrors({ ...errors, [name]: null });
    }
  };

  return (
    <div className="mx-auto max-w-7xl ">
      <div className="flex flex-col items-center justify-center space-y-4 md:py-12 lg:py-12 py-6 md:px-0 lg:px-0 px-4">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Airport Transfer, Group Booking, Late Night or Corporate Account?
        </h3>
        <p className="mt-1 text-lg text-gray-500">
          Please fill out the form below with your enquiry
        </p>
        <div className="grid  grid-cols-3 w-full">
          <div className="md:col-span-1 lg:col-span-1 col-span-3 py-4  space-y-4">
            <CustomInput
              type="text"
              name="name"
              label="Name"
              value={enquiry.name}
              placeholder="Name..."
              handleChange={(e) => setEnquiry({ ...enquiry, name: e })}
              errorMessage={getError("name")}
              blurHandler={handleBlur}
            />
            <CustomInput
              type="email"
              name="email"
              label="Email"
              value={enquiry.email}
              placeholder="Email Address..."
              handleChange={(e) => setEnquiry({ ...enquiry, email: e })}
              errorMessage={getError("email")}
              blurHandler={handleBlur}
            />
            <CustomInput
              maxLength="11"
              pattern="[0-9]*"
              type="text"
              name="phone"
              label="Phone Number"
              value={enquiry.phone}
              placeholder="07 12345 6789"
              handleChange={(e) => setEnquiry({ ...enquiry, phone: e })}
              errorMessage={getError("phone")}
              blurHandler={handleBlur}
            />
          </div>
          <div className="md:col-span-2 lg:col-span-2 col-span-3 p-4 md:px-8 lg:px-8 px-0 ">
            <div>
              <label
                htmlFor="comment"
                className="block text-sm font-medium text-gray-900"
              >
                Your Message
              </label>
              <div className="mt-2">
                <textarea
                  onChange={(e) =>
                    setEnquiry({ ...enquiry, message: e.target.value })
                  }
                  rows={8}
                  name="message"
                  id="message"
                  value={enquiry.message ? enquiry.message : ""}
                  className="resize-none px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-amber-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  placeholder="Leave a message..."
                  onBlur={(e) => handleBlur(e)}
                />
                {getError("message") && (
                  <p className="mt-2 text-sm text-red-600">
                    {getError("message")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          disabled={showLoading}
          onClick={sendEnquiry}
          type="button"
          className=" text-white  bg-amber-600 hover:bg-amber-800   rounded-3xl  px-8 py-3 text-center mr-2 mb-2 inline-flex items-center capitalize gap-x-1"
        >
          {showLoading && <Loader cssClass={"text-black"} isSmallView />}
          Send
        </button>
      </div>
    </div>
  );
};
export default Contact;
