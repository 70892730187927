import React, { useEffect } from "react";
import SectionService from "../components/sectionservice";
import SectionOneHomePage from "../components/sectiononehomepage";
import SectionTwoHomePage from "../components/sectiontwohomepage";
import SectionThereHomePage from "../components/sectiontherehomepage";
import Header from "../components/header";

const LandingPage = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <main>
      <Header />
      <SectionService />
      <SectionOneHomePage />
      {/* <SectionTwoHomePage /> */}
      {/* <SectionThereHomePage /> */}
    </main>
  );
};
export default LandingPage;
