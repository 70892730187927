import React from "react";

const CustomInput = (props) => {
  const {
    label,
    type,
    name,
    placeholder,
    value,
    max,
    errorMessage,
    blurHandler,
    maxLength,
    isDisabled = false,
    handleChange,
  } = props;

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="mt-2 rounded-md shadow-sm">
        <input
          disabled={isDisabled}
          maxLength={maxLength}
          max={max}
          type={type}
          name={name}
          id={name}
          className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-amber-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          value={value ? value : ""}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={(e) => {
            if (blurHandler) blurHandler(e);
          }}
        />
      </div>
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInput;
