import sevenSeaterTaxi from "../public/sevenseater.jpg";
import { useNavigate } from "react-router";

const Contents = () => {
  const navigate = useNavigate();

  // #f7f7f7
  return (
    <div className="bg-[#f2f2f2] px-4">
      <div className="mx-auto max-w-7xl ">
        <div className="md:py-20 lg:py-20 sm:py-12 space-y-20 w-full ">
          {/* <div className=" lg:flex lg:flex-row justify-center md:px-16 lg:px-16 px-4">
          <div>
            <img src={Image1} alt="Picture of the author" />
          </div>
          <div className="mt-12">
            <img src={Image2} alt="Picture of the author" />
          </div>
        </div> */}
          {/* <div
          style={{ marginTop: "0px" }}
          className="md:py-24 lg:py-24 py-12 flex flex-col justify-center items-center text-center md:px-16 lg:px-16 px-4"
        >
          <FaHashtag className="w-12 h-12 text-amber-600  p-1" />
          <p className="text-3xl font-light text-gray-600">
            I invite you to try our service and I personally guarantee you will
            <br />
            have a fully satisfied experience.
          </p>
          <p className="text-xl text-gray-500">Mim Taxis - CHAIRMAN CEO</p>
        </div> */}
          <div
            className="lg:grid lg:grid-cols-2 md:px-16 lg:px-16 px-4"
            style={{ marginTop: "0px" }}
          >
            <div className="col-span-1 space-y-6 py-12 text-center">
              <p className="text-3xl font-medium text-gray-600">
                Corporate Accounts
              </p>
              <p className="text-2xl font-light text-gray-500">
                Opening a corporate account with us gives you the benefit of
                paying monthly invoices, email booking confirmation and
                dedicated account manager.
                {/* Our goal is to make your travel safe, effortless and on schedule. */}
              </p>
              <button
                onClick={() => navigate("/contact")}
                type="button"
                className="text-white  bg-amber-600 hover:bg-amber-800   rounded-3xl  px-8 py-3 text-center mr-2 mb-2 "
              >
                CONTACT US
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <div className="col-span-1 row-span-2">
                <img src={sevenSeaterTaxi} alt="Picture of the 7 seater taxi" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contents;
