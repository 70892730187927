import React, { useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const tiers = [
  {
    name: "MIM Taxi Service",
    id: "tier-basic",
    href: "/contact",

    description: "We accept advance booking all year around.",
    features: [
      "Extended Working Hours",
      "365 days a year",
      "Credit Card Payments Accepted",
      "Wheelchair Accessible",
    ],
  },
  {
    name: "Airport Transfers",
    id: "tier-essential",
    href: "/contact",

    description: "Start your trip hassle free.",
    features: [
      "Meet and Greet Service",
      "London Heathrow",
      "London Gatwick",
      "All other Airports",

      // "7 seaters",
    ],
  },
  {
    name: "Group Bookings",
    id: "tier-growth",
    href: "/contact",
    description: "Plan your trip with friends or family.",
    features: [
      "Multiple pickups",
      "Multiple drop offs",
      "Local or long distance",
      "Up to 7 passengers per taxi",
    ],
  },
];

const Services = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-12">
        <div className="mx-auto max-w-4xl sm:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-amber-600">
            Pricing
          </h2> */}
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl text-center">
            MIM Taxi Services
          </p>
        </div>
        {/* <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
          Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
          quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
        </p> */}
        <div className="mt-10 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
            {tiers.map((tier) => (
              <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <div>
                  <div
                    id={tier.id}
                    className="text-lg font-semibold leading-7 text-gray-900 text-center"
                  >
                    {tier.name}
                  </div>
                  {/* <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {tier.price.monthly}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /month
                  </span>
                </p> */}
                  {/* <p className="mt-3 text-sm leading-6 text-gray-500">
                  {tier.price.annually} per month if paid annually
                </p> */}

                  <p className="mt-10 text-sm font-semibold leading-6 text-gray-900 text-start">
                    {tier.description}
                  </p>
                  <ul
                    role="list"
                    className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckCircleIcon
                          className="h-6 w-5 flex-none text-amber-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <Link
                    to={tier.href}
                    className="mt-10 block rounded-md bg-amber-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                  >
                    Book a ride now
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
