import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer";
import NewNavBar from "./components/newNavbar";
import LandingPage from "./pages/LandingPage";
import Contact from "./pages/contact";
import Services from "./pages/Services";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <NewNavBar />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </>
  );
};

export default App;
